import styled from "styled-components"

export const TableStyled = styled.table`
  border: 1px solid #e1e3e6;
  border-collapse: collapse;
  margin: auto;
  padding: 0;
`

export const Th = styled.th`
  background-color: #f4f4f5;
  font-weight: bold;
  padding: 0.625em 1.2em;
  border: 1px solid #e1e3e6;
  vertical-align: middle;

  @media screen and (max-width: 600px) {
    &.number {
      display: none;
    }
  }

  @media screen and (max-width: 390px) {
    font-size: 15px;
    padding: 0.625em 0.45em;
  }
`

export const Td = styled.td`
  background-color: #f4f4f5;
  padding: 0.625em 1.2em;
  border: 1px solid #e1e3e6;
  vertical-align: middle;

  &.number,
  &.value {
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    &.number {
      display: none;
    }
  }

  @media screen and (max-width: 390px) {
    font-size: 15px;
    padding: 0.625em 0.45em;
  }
`
