import React from "react"
import { TableStyled, Td, Th } from "./Table.styled"

export default ({ values }) => (
  <TableStyled>
    <thead>
      <tr>
        <Th className="number">№</Th>
        <Th>{values.th[0]}</Th>
        <Th className="value">{values.th[1]}</Th>
      </tr>
    </thead>
    <tbody>
      {values.td.map((el, i) => (
        <tr key={el[0]}>
          <Td className="number">{i + 1}</Td>
          <Td>{el[0]}</Td>
          <Td className="value">{el[1]}</Td>
        </tr>
      ))}
    </tbody>
  </TableStyled>
)
