import React from "react"

import Table from "../../components/Table/Table"
import {
  Delimiter,
  MainTitle,
  PhotoStyled,
  SubTitle,
  Text,
  VideoContainer,
} from "../Catalog/PageCatalog.styled"
import productsData from '../../data/products'
import Product from "../../components/Product/Product"
import { graphql, Link, useStaticQuery } from "gatsby"
import Feedback from "../../components/Feedback/Feedback"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import Wrapper from "../../components/Wrapper/Wrapper"
import Seo from "../../config-components/seo"
import Layout from "../../layouts/MainLayout/Layout"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

const accessiories = {
  th: ["Позиция", "Цена"],
  td: [
    ["колеса для перемещения по суше", "1500р / комплект"],
  ],
}

const MarinaM1 = ({ location, pageContext }) => {
  const query = useStaticQuery(graphql`
    query MarinaM1Query {
      photos: allFile(
        filter: {
          relativeDirectory: { regex: "/images/catalog/marina-m1/product/" }
        }
      ) {
        edges {
          node {
            publicURL
            relativeDirectory
          }
        }
      }
      specs: allFile(
        filter: {
          relativeDirectory: { regex: "/images/catalog/marina-m1/photos/" }
          name: { eq: "katamaran-m1-specs" }
        }
      ) {
        edges {
          node {
            publicURL
          }
        }
      }
      docs: allFile(filter: { relativeDirectory: { regex: "/docs/" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  const queryDoc = (name) =>
    query.docs.edges.filter((el) => el.node.name === name)[0].node.publicURL

  const images = query.photos.edges.map((el) => ({
    url: el.node.publicURL,
    alt: el.node.relativeDirectory.split("/").slice(-1)[0],
  }))

  const specs = query.specs.edges[0].node.publicURL

  const price = queryDoc("katamarans-price")
  const sborka = queryDoc("katamaran-m1-sborka")
  const razborka = queryDoc("katamaran-m1-razborka")

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const {marinaM1} = productsData
  const {specification} = marinaM1

  return (
    <Layout>
      <Header page={location.pathname} />

      <Wrapper width={754}>
        <div style={{ width: "100%" }}>
          <Breadcrumb
            crumbs={crumbs}
            crumbLabel="Marina-M1"
            crumbSeparator=" > "
          />
        </div>
        <Seo
          title={
            "Купить надувной катамаран, купить водный велосипед по отличной цене!"
          }
          description={
            "Купить катамаран педальный MARINA-M1 от производителя. Наш надувной катамаран поместится багажник авто. Купить водный велосипед по лучшей цене с доставкой."
          }
          keywords={
            "водный велосипед купить, надувной катамаран купить, купить катамаран педальный"
          }
        />
        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          Надувной катамаран (водный велосипед) <br /> MARINA-M1
        </MainTitle>
        <Product
          images={images}
          info={marinaM1.mainInfo}
          price={marinaM1.price}
          priceStyle={{ color: "red" }}
        >
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 25 }}
          >
            <a href={price} target="_blank" rel="noopener noreferrer">
              Скачать прайс
            </a>
          </div>
        </Product>

        <Delimiter style={{ paddingTop: 0 }} />

        <SubTitle>Видеообзор катамарана MARINA-M1</SubTitle>
        <VideoContainer>
          <iframe
            allowFullScreen="allowfullscreen"
            src="https://www.youtube.com/embed/BapmjxEJ8R4"
            frameBorder="0"
          />
        </VideoContainer>

        <SubTitle>Видеообзор катамарана MARINA-M1 RAFTING</SubTitle>
        <VideoContainer>
          <iframe
            allowFullScreen="allowfullscreen"
            src="https://www.youtube.com/embed/txthjCQKvJU"
            frameBorder="0"
          />
        </VideoContainer>

        <Delimiter />

        <SubTitle>Технические характеристики</SubTitle>
        <a href={specs} target="_blank" rel="noopener noreferrer">
          <PhotoStyled
            src={specs}
            alt="катамаран педальный - технические характеристики"
            title="катамаран педальный - технические характеристики"
          />
        </a>
        <Table values={specification} />

        <Delimiter />

        <SubTitle>Общая информация о водном велосипеде</SubTitle>
        <Text>
          <p>
            Велокатамаран «MARINA-M1» - это простота и надежность конструкции.
            Мы постарались найти баланс между комфортом эксплуатации,
            безотказностью и доступной стоимостью.
          </p>
          <p>
            Баллоны сделаны из высококачественной трехслойной лодочной ПВХ-ткани
            плотностью 750/850 г/м2 от ведущих мировых производителей. Баллоны
            могут быть различных цветов, в т.ч. и комбинированных, в
            двухкамерном исполнении. Если вам необходим водный велосипед для
            усиленного режима эксплуатации (прокат, водоемы с каменистым дном и
            т.д.), то мы можем опционально изготовить баллоны из ПВХ ткани
            плотностью 1050 г/м2, а также произвести усиление днища баллонов
            специальной лентой.
          </p>
          <p>
            Рама надувного катамарана целиком изготовлена из нержавеющей стали,
            устойчива к коррозии и не требуют регулярного обслуживания.
          </p>
          <p>
            Механизм крепления велопривода к раме водного велосипеда позволяет
            легко преодолевать подводные препятствия, мели, осуществлять выход
            на берег без риска повреждения винта.
          </p>
          <br />
          <p>
            <b>
              Складная конструкция рамы облегчает транспортировку педального
              катамарана и позволяет:
            </b>
          </p>
          <ul>
            <li>
              <i>
                • использовать его как развлекательное плавательное средство для
                пляжного отдыха семьей или дружеской компанией;
              </i>
            </li>
            <li>
              <i>
                • брать с собой в туристические пешие и велопоходы для
                преодоления водных преград;
              </i>
            </li>
            <li>
              <i>
                • организовать бизнес по прокату надувных катамаранов как на
                базе отдыха, так и на самом труднодоступном пляже.
              </i>
            </li>
          </ul>
          <br />
          <p>
            <b>Купить водный велосипед просто:</b>
          </p>
          <ul>
            <li>
              <i>
                • Для покупки велокатамарана воспользуйтесь сервисом обратной
                связи. Наши менеджеры свяжется с Вами для обсуждения
                комплектации и условий поставки.
              </i>
            </li>
            <li>
              <i>
                • Доставка во все регионы России транспортными компаниями, с
                обязательным страхованием груза.
              </i>
            </li>
            <li>
              <i>• Гарантия в соответствии с законодательством РФ.</i>
            </li>
          </ul>
          <br />
          <p>
            {" "}
            Некоторые наши клиенты предпочитают купить надувной катамаран для
            себя и семьи, подарив много положительных эмоций детям, родным и
            друзьям при совместных выездах на природу.
          </p>
          <p>
            {" "}
            Другие хотят купить водный велосипед для организации проката. Они
            получают качественное изделие, не требующее обслуживания и
            специальных условий хранения. Бизнес с нашими надувными катамаранами
            мобилен и позволит быть там, где вам хочется, просто транспортируя
            судно в багажнике автомобиля.
          </p>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href={sborka} target="_blank" rel="noopener noreferrer">
              Скачать инструкцию по сборке
            </a>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href={razborka} target="_blank" rel="noopener noreferrer">
              Скачать инструкцию по подготовке к транспортировке
            </a>
          </div>
        </Text>

        <Delimiter />

        <SubTitle>Дополнительные аксессуары к педальному катамарану</SubTitle>
        <Table values={accessiories} />

        <div style={{ paddingTop: 20 }}>
          <Link to="/catalog/accessories">Еще аксессуары и запчасти</Link>
        </div>

        <Delimiter />

        <div id={`feedback`}>
          <Feedback styles={{ marginBottom: "2rem" }} />
        </div>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default MarinaM1
